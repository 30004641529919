import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { TopSection } from "../components/Serebro/TopSection"
import { PricesSection } from "../components/Serebro/PricesSection"
import { OnePriceSection } from "../components/Serebro/OnePriceSection"
import { ServicesSection } from "../components/Serebro/ServicesSection"
import { NewsSection } from "../components/Serebro/NewsSection"
import { MapSection } from "../components/Serebro/MapSection"
import { HorizontalCard } from "../components/Serebro/MapSection/HorizontalCard"

const SerebroPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Прием технического серебра в Калуге и по всей России. Скупка радиодеталей."
          description="Бесплатный выезд специалиста по Центральной России. 
        Мы гарантируем проведение анализа и выплату в день получения отработанного материала. 
        Наша компания скупает техническое серебро и радиодетали по высоким ценам до 75% LME."
        />
      }

      <div className="overflow-hidden bg-gray-100 text-gray-900">
        <TopSection />
        <PricesSection />
        <OnePriceSection />
        <ServicesSection />
        <NewsSection />
        <MapSection>
          <HorizontalCard />
        </MapSection>
      </div>
    </Layout>
  )
}

export default SerebroPage
