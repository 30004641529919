import React from "react"

import { useSetRecoilState } from "recoil"
import { serebroModalOpen } from "../../atoms"

import { Services } from "./Services"

export function ServicesSection() {
  const setSerebroModalOpen = useSetRecoilState(serebroModalOpen)
  return (
    <section
      id="services"
      aria-labelledby="services-heading"
      className="mx-auto max-w-xl py-6 px-0 sm:py-20 lg:max-w-7xl"
    >
      <h2
        id="services-heading"
        className="text-center text-xl font-bold text-gray-900 sm:text-4xl"
      >
        Дополнительные услуги
      </h2>
      <h3 className="px-1 pt-2 text-center text-base font-light md:text-2xl">
        КаталикАвто - динамично развивающаяся компания. Мы расширяем сферы своей
        деятельности и осуществляем прием не только технического серебра:
      </h3>

      <Services />

      <div className="flex justify-center">
        <button
          onClick={() => setSerebroModalOpen(true)}
          className="flex items-center justify-center rounded-full border-0 bg-corp-orange py-4 px-4 text-base font-bold text-white
              hover:bg-orange-500  hover:text-gray-100 sm:py-4 sm:px-10 sm:text-2xl"
        >
          Задать вопрос
        </button>
      </div>
    </section>
  )
}
