import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { useSetRecoilState } from "recoil"
import { serebroModalOpen } from "../../atoms"

export function OnePriceSection() {
  const setSerebroModalOpen = useSetRecoilState(serebroModalOpen)

  return (
    <section
      id="services"
      aria-labelledby="services-heading"
      className=" bg-gray-50 py-6 px-0 sm:py-20 "
    >
      <div className="mx-auto max-w-xl lg:max-w-7xl">
        <h2
          id="services-heading"
          className="text-center text-xl font-bold text-gray-900 sm:text-4xl"
        >
          Техническое серебро по самым высоким ценам в РФ
        </h2>
        <h3 className="px-1 pt-2 text-center text-base font-light md:text-2xl">
          Профессиональный лабораторный анализ и оценка материала
        </h3>

        <div className="grid grid-cols-1 gap-x-2 pt-2 sm:grid-cols-2 sm:gap-x-16 sm:pt-16">
          <div className="justify-self-center overflow-hidden rounded-md sm:justify-self-end ">
            <StaticImage
              src="../../../assets/images/serebro/80.jpg"
              alt="Техническое серебро"
              height={360}
            />
          </div>
          <div className="flex flex-col items-center space-y-4 pt-2 md:block">
            <h4 className="text-center text-xl text-gray-900 md:text-left md:text-2xl">
              Цены на тех. серебро
            </h4>
            <p className="max-w-md text-base font-light text-gray-700 md:text-lg">
              Наша команда экспертов использует передовые модели оборудования
              для точной оценки содержимого. Мы принимаем крупные и небольшие
              партии технического серебра, основываясь на данных лабораторного
              анализа. Возможен выезд менеджера на ваше производство.
            </p>
            <button
              onClick={() => setSerebroModalOpen(true)}
              className="rounded-md bg-gray-700 px-16 py-4 text-white shadow transition-colors hover:bg-white hover:text-gray-700"
            >
              Узнать цену
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
