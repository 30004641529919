import React, { useState } from "react"
import { Link } from "gatsby"

import { CheckCircleIcon } from "@heroicons/react/solid"

import { checkPhone } from "../../helpers"

export function Form() {
  const [city, setCity] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [checked, setChecked] = useState(true)

  const [initial, setInitial] = useState(true)
  const [success, setSuccess] = useState(false)

  const clearForm = () => {
    setCity("")
    setPhone("")
    setMessage("")
  }

  const onSend = () => {
    setInitial(false)

    const isPhoneOk = checkPhone(phone)

    if (isPhoneOk) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          chat_id: "-657812950",
          text: `📧 Новая заявка\nГород: ${city}\nНомер: ${phone}\nСообщение: ${message}\nПромышленное серебро `,
        }),
      }

      fetch(
        "https://api.telegram.org/bot5224830184:AAEv9YpoCKGSqGqq9NtZC58i0YtkAc78K7c/sendMessage",
        requestOptions
      )
        .then(response => response.json())
        .then(data => {
          if (data.ok) {
            setSuccess(true)
            clearForm()
          }
        })
        .catch(error => {
          setSuccess(false)
          console.log(error)
        })
    }
  }

  return (
    <div className="col-span-1 content-center">
      <div className="mt-3 sm:mt-24 lg:col-span-5 lg:mt-0">
        <div
          className="mx-auto w-full max-w-lg space-y-4 overflow-hidden rounded-lg 
          bg-white bg-opacity-30 p-4 backdrop-blur-lg backdrop-filter"
        >
          <p className="text-center text-2xl font-semibold text-white">
            Кас сдать серебро?
          </p>
          <p className="text-white">
            Оставьте свой вопрос в форме ниже, и наш специалист свяжется с вами
            в ближайшее время
          </p>
          <div className="grid grid-cols-1 gap-2">
            <div>
              <label htmlFor="city" className="sr-only">
                Ваш город
              </label>
              <input
                value={city}
                onChange={e => setCity(e.target.value)}
                type="text"
                name="city"
                id="city"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-corp-orange sm:text-sm sm:leading-6"
                placeholder="Ваш город"
              />
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Ваш номер
              </label>
              <input
                value={phone}
                onChange={e => setPhone(e.target.value)}
                type="tel"
                name="phone"
                id="phone"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-corp-orange sm:text-sm sm:leading-6"
                placeholder="Ваш номер"
              />
              {!initial && !checkPhone(phone) ? (
                <p className="mt-1 text-sm text-red-400" id="email-error">
                  Неправильный формат <br />
                  Ожидается +71231234567 или 81231234567
                </p>
              ) : null}
            </div>
            <div>
              <label htmlFor="message" className="sr-only">
                Сообщение
              </label>
              <textarea
                value={message}
                onChange={e => setMessage(e.target.value)}
                rows="2"
                name="message"
                id="message"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-corp-orange sm:text-sm sm:leading-6"
                placeholder="Сообщение"
              />
            </div>
            <div className="flex items-center">
              <input
                checked={checked}
                onChange={() => setChecked(!checked)}
                id="agreed"
                name="agreed"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-corp-orange focus:ring-orange-600"
              />
              <label
                htmlFor="agreed"
                className="ml-2 block text-sm leading-6 text-white"
              >
                Согласен с{" "}
                <Link
                  to="/politika-konfidenczialnosti"
                  className="text-white underline underline-offset-2 hover:text-corp-orange"
                >
                  политикой конфиденциальности
                </Link>
              </label>
            </div>
            <button
              onClick={onSend}
              disabled={success || !checked}
              className="inline-flex items-center justify-center overflow-hidden rounded-lg bg-white bg-opacity-10 p-2 text-white backdrop-blur-lg backdrop-filter enabled:hover:bg-opacity-0 disabled:cursor-not-allowed disabled:opacity-75"
            >
              {success ? (
                <>
                  Отправлено
                  <CheckCircleIcon
                    className="ml-2 h-6 w-6 text-green-300"
                    aria-hidden="true"
                  />
                </>
              ) : (
                "Отправить"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
