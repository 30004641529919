import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { ActionButtons } from "./ActionButtons"
import { Perks } from "./Perks"
import { Overlay } from "./Overlay"
import { Form } from "./Form"

export function TopSection() {
  return (
    <div className="relative bg-gray-900">
      <div aria-hidden="true" className="absolute inset-0 z-10 overflow-hidden">
        <StaticImage
          src="../../../assets/images/site-bg.png"
          alt="Фон КаталикАвто"
          placeholder="blurred"
          className="h-full w-full object-cover object-center"
        />
      </div>

      <Overlay />

      <div className="relative z-30 mx-auto flex max-w-7xl flex-col  justify-between py-16 px-6 pt-0 text-left sm:pt-80 lg:px-0">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <div className="col-span-2 pt-28 text-center sm:pt-0 sm:text-left">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl ">
              Прием{" "}
              <span className="text-corp-orange">
                технического
                <br />
              </span>{" "}
              серебра и<span className="text-corp-orange"> лома</span>{" "}
            </h1>
            <p className="mt-4 text-2xl text-white sm:mt-8 sm:text-3xl">
              По всей России и СНГ
            </p>

            <ActionButtons />
          </div>

          <Form />
        </div>
        <Perks />
      </div>
    </div>
  )
}
