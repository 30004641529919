import React from "react"

import { Carrousel } from "./Carrousel"

export function PricesSection() {
  return (
    <section
      id="services"
      aria-labelledby="services-heading"
      className="mx-auto max-w-xl py-6 px-0 sm:py-20 lg:max-w-7xl"
    >
      <h2
        id="services-heading"
        className="text-center text-xl font-bold text-gray-900 sm:text-4xl"
      >
        Техническое серебро - приём в Калуге
      </h2>
      <h3 className="px-1 pt-2 text-center text-base font-light md:text-2xl">
        Выкуп материала по самым выгодным условиям в Калуге и области
      </h3>

      <Carrousel />
    </section>
  )
}
