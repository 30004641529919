import React from "react"

import { useSetRecoilState } from "recoil"
import { serebroModalOpen } from "../../atoms"

import img0 from "../../../assets/images/serebro/1-1.jpg"
import img2 from "../../../assets/images/serebro/3-3.jpg"
import img3 from "../../../assets/images/serebro/4-4.jpg"
import img5 from "../../../assets/images/serebro/5-5.jpg"
import img4 from "../../../assets/images/serebro/med.jpg"
import img1 from "../../../assets/images/serebro/rele2.jpg"

export function Carrousel() {
  const setSerebroModalOpen = useSetRecoilState(serebroModalOpen)

  const materials = [
    { title: "Серебро", description: "Содержание серебра 100%", img: img0 },
    {
      title: "Серебро контакты реле",
      description: "Содержание серебра 95%",
      img: img1,
    },
    {
      title: "Серебро не магнитное",
      description: "Содержание серебра 80%",
      img: img2,
    },
    {
      title: "Серебро магнитное",
      description: "Содержание серебра 60%",
      img: img3,
    },
    {
      title: "Серебро на меди",
      description: "Содержание серебра 30%",
      img: img4,
    },
    {
      title: "Серебро контакты на подложке",
      description: "Содержание серебра 12%",
      img: img5,
    },
  ]

  return (
    <div className="container mx-auto w-full flex-grow px-4 py-8 sm:px-0">
      <div className="mx-auto w-full">
        <div className="container">
          <div
            id="newsScrollContainer"
            className="flex-no-wrap scrolling-touch flex items-start gap-x-2 overflow-x-scroll sm:grid sm:grid-cols-3 lg:overflow-x-hidden"
          >
            {materials.map((material, index) => (
              <div
                key={`material-${index}`}
                className="group w-2/3 flex-none md:w-full md:pb-4"
              >
                <div className="mx-2 overflow-hidden rounded-lg border bg-white drop-shadow-md group-hover:drop-shadow-lg">
                  <div className="!aspect-w-16 !aspect-h-9 overflow-hidden">
                    <img
                      src={material?.img}
                      alt={`Фото ${material.title}`}
                      className="rounded-t-lg bg-white object-cover duration-500 ease-in-out group-hover:rotate-6 group-hover:scale-125"
                    />
                  </div>
                  <div className="px-4 py-2 text-center">
                    <div className="space-y-1">
                      <h4 className="mb-2 pt-1 text-base font-medium text-gray-900 sm:text-lg">
                        {material.title}
                      </h4>
                    </div>

                    <p className="text-sm text-gray-600 sm:text-base">
                      {material.description}
                    </p>

                    <button
                      onClick={() => setSerebroModalOpen(true)}
                      className="text-sm text-corp-orange hover:text-orange-600 sm:text-base"
                    >
                      Узнать стоимость
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
